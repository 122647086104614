@import url("https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i&display=swap&subset=latin-ext");
@import url("https://fonts.googleapis.com/css?family=Great+Vibes&display=swap");
@import url("https://fonts.googleapis.com/css?family=Mr+Dafoe&display=swap");

body,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  font-family: "Montserrat", sans-serif;
}

section {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 0;
  height: 100%;
}

.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.video-gallery {
  padding: 0 15px !important;
}

.loading-now {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  background: rgba(255, 255, 255, 0.9);
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 999999999;
  align-content: center;
  align-items: center;
  text-align: center;
}

.wave-spinner {
  display: block;
  width: 60px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.wave-spinner p {
  text-align: center;
  font-size: 12px;
  padding-top: 30px;
}

.loading-now-border {
  position: absolute;
  top: 50%;
}

.loading-now-grow {
  position: absolute;
  top: 50%;
  margin: 8px;
}

.col-logo {
  background-color: hsla(0, 0%, 95%, 0.9);
  height: 100%;
  display: inline-block;
}

.logo-class {
  display: block;
  text-align: center;
}

.logo-class img {
  padding: 20px 10px;
  max-width: 70px;
}

.col-menu {
  display: inline-block;
  align-content: center;
  align-items: center;
  background-color: hsla(0, 0%, 90%, 0.9);
  text-align: center;
}

.mr-auto .navbar-nav {
  display: flex !important;
  flex-basis: auto;
  flex-grow: 1;
  width: 100%;
  align-content: center;
  align-items: center;
}

.navbar {
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

.navbar-nav {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
}

.menu-items {
  display: inline-flexbox;
  text-align: center;
}

.menu-items .active {
  color: #000;
}

.menu-items a {
  color: #b79a20;
  margin: 0 10px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

.menu-items a:hover {
  text-decoration: none;
  color: #474747;
}

.menu-item-hide {
  margin-left: 5px;
}

.col-player {
  display: flex;
  background-color: hsl(0, 0%, 13%);
  vertical-align: middle;
  align-items: center;
  align-content: center;
}

@media only screen and (max-width: 1060px) {
  .menu-items a {
    font-size: 12px;
    font-weight: 500;
  }
}

@media only screen and (max-width: 768px) {
  .menu-items a {
    font-size: 18px;
    font-weight: 500;
  }

  .menu-item-hide {
    display: none;
  }
}

@media only screen and (max-width: 414px) {
  .logo-class img {
    max-width: 120px;
    padding: 5px 10px;
  }

  section {
    padding: 25px 0;
  }
}

/* Home page styling */

.home-hero {
  background-image: url("./resources/images/bg/dorothy-ophals-piano.jpg");
  background-size: cover;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  min-height: 600px;
  align-content: center;
  background-position: center;
  padding-top: 5%;
  padding-bottom: 15%;
}

.welcome-home {
  position: relative;
  z-index: 1;
  margin-left: 10%;
  margin-right: 10%;
}

.subtitle-home {
  color: #fff;
  font-size: 44px;
  text-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  line-height: 8px;
}

.title-home {
  color: #fff;
  font-size: 70px;
  font-weight: 700;
  text-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.title-home span {
  font-family: "Great Vibes", cursive;
  font-weight: 200;
  font-size: 100px;
  color: rgb(0, 0, 0);
  margin-left: -60px;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
    1px 1px 0 #fff, 2px 2px 7px rgba(0, 0, 0, 0.75);
}

.caption-home {
  color: #000;
  font-weight: 600;
  font-size: 24px;
}

@media only screen and (max-width: 1024px) {
  .home-hero {
    min-height: 500px;
  }

  .subtitle-home {
    font-size: 20px;
    line-height: 15px;
  }

  .title-home {
    font-size: 40px;
    line-height: 45px;
  }

  .title-home span {
    font-size: 60px;
    margin-left: -40px;
  }

  .caption-home {
    font-size: 18px;
  }
}

@media only screen and (max-width: 768px) {
  .col-menu {
    height: 90px;
  }
}

@media only screen and (max-width: 414px) {
  .caption-home {
    color: #ffffff !important;
    text-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  }

  .col-menu {
    width: 70%;
  }

  .col-player {
    width: 30%;
  }
}

/* Timeline Home design */

.timeline-intro {
  align-content: center;
  text-align: center;
}

.timeline-intro img {
  width: 90%;
}

.timeline-intro p {
  width: 90%;
  max-width: 900px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}

.timeline-item-dateinner {
  background: #b79a20 !important;
}

.timeline h4 {
  font-size: 18px;
  margin-bottom: 35px !important;
  color: hsl(0, 0%, 46%);
}

/* Cards in Home Design */

.boxes {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 50px;
}

.boxes-1 {
  margin: 20px 10px 20px 0;
  display: inline-block;
  background-color: #ececec;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  width: 100%;
  background-image: url("./resources/images/dorothy-ophals-compositor.jpg");
  background-size: cover;
  text-align: left;
  height: 100%;
}

.boxes-1 h3 {
  color: #ffffff;
  width: 70%;
  margin-left: 30px;
  margin-top: 20%;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.75);
}

.boxes-1 h5 {
  color: #ffffff;
  width: 50%;
  margin-left: 30px;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.75);
}

.boxes-1 button {
  margin-left: 30px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.75);
}

.boxes-2 {
  margin: 20px 0 20px 10px;
  display: inline-block;
  background-color: #ececec;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 100%;
}

.boxes-2 h3 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.home-album-left {
  width: 35%;
  display: block;
  float: left;
}

.home-album-left img {
  display: block;
  width: 100%;
  padding: 0 20px;
}

.home-album-right {
  width: 60%;
  display: block;
  float: left;
}

.list-group-item {
  background-color: transparent !important;
  margin: 0 20px;
}

@media only screen and (max-width: 767px) {
  .boxes-1 {
    margin: 0;
    min-height: 375px;
  }
  .boxes-2 {
    margin: 50px 0;
    display: flex;
    flex-direction: column;
  }

  .home-album-left {
    width: 100%;
    display: block;
    clear: both;
  }

  .home-album-left img {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .home-album-right {
    width: 100%;
    display: block;
    clear: both;
  }
}

/* Customs songs composition service page */

.cs-first-column {
  width: 100%;
  max-width: 1200px !important;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 25px;
}

.cs-first-column h2 {
  font-family: "Mr Dafoe", cursive;
  font-size: 80px;
  color: #b79a20;
  margin-left: 10%;
  line-height: 20px;
}

.cs-first-column h1 {
  font-size: 90px;
  font-weight: 500 !important;
}

.cs-second-column {
  width: 100%;
}

.cs-second-column img {
  width: 100%;
  padding-right: 30px;
}

/* Custom songs compostions service page *
** Service Features Explination */

.features {
  text-align: center;
  padding: 30px 0;
}

.features img {
  margin-bottom: 40px;
}

.features-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}

.features-content {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

/* Custom songs compostions service page *
** Events Explination */

.sing-title h3 {
  font-weight: 800;
  font-size: 34px;
}

.sing-title h2 {
  font-family: "Mr Dafoe", cursive;
  font-size: 80px;
  color: #b79a20;
  margin-left: 10%;
  margin-top: -20px;
}

.events-list:first-child {
  margin-left: 10%;
}

.events-list {
  margin-right: 40px;
}

.events-list p {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 45px;
}

.studio-dot {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .cs-first-column h2 {
    font-size: 70px;
    line-height: 60px;
  }

  .cs-first-column h1 {
    font-size: 60px;
  }

  .events-list p {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 35px;
  }

  .events-list {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 414px) {
  .cs-first-column {
    margin-bottom: 50px;
  }

  .media-title {
    margin-bottom: 70px;
  }

  .cs-first-column h2 {
    font-size: 50px;
    line-height: 40px;
    margin-left: 0;
    text-align: center;
  }

  .cs-first-column h1 {
    font-size: 40px !important;
    line-height: 50px;
    text-align: center;
  }

  .cs-second-column img {
    margin-bottom: 30px;
  }

  .events-list:first-child {
    margin-left: 5%;
  }

  .bio-quote {
    margin-right: auto !important;
  }
}
@media only screen and (max-width: 375px) {
  .cs-first-column h2 {
    font-size: 40px;
    line-height: 35px;
  }

  .cs-first-column h1 {
    font-size: 40px;
    line-height: 40px;
  }

  .events-list {
    margin-right: 0px;
  }

  .events-list p {
    font-size: 16px !important;
    font-weight: 600;
  }

  .events-list:first-child {
    margin-left: 0;
  }
}

/* Bio page style */

.bio-quote {
  font-size: 24px;
  font-style: italic;
  width: 80%;
  max-width: 900px;
  margin-left: auto;
  margin-right: 0;
}

.cs-first-column sub {
  float: right;
}

.left-bio img {
  width: 90%;
}

@media only screen and (max-width: 414px) {
  .left-bio img {
    width: 100%;
  }

  .right-bio {
    margin-top: 30px;
  }
}

/* Contact Dorothy Ophals page */

.block-on-map {
  display: block;
  width: 250px;
  height: 250px;
  background: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  margin-top: -100px;
}

.block-on-map img {
  display: block;
  width: 100%;
}

.info-cols {
  text-align: center;
}

.info-cols h4 {
  margin-top: 40px;
}

.contact-info i {
  font-size: 70px;
}

.email-button {
  width: 100%;
  cursor: pointer;
}

.logo-in-conact {
  width: 40%;
}

/* Contact form */
.col-field {
  padding: 5px 30px;
}

.form-group {
  font-family: "Montserrat", sans-serif;
  padding: 0 20px !important;
  display: table;
  width: 100%;
}

.social-input {
  background-color: #ededed;
  padding: 30px 30px;
  margin: 0 0 50px 10px !important;
}

.social-icons-input {
  float: left;
  width: 50px;
  vertical-align: middle;
  display: table-cell;
}

.social-icons-input i {
  font-size: 30px;
  line-height: 0.2em;
  display: block;
  margin-top: 5px;
}

.social-inputs {
  display: table-cell;
  width: 100%;
}

.form-group input {
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
  width: 100%;
  min-height: 50px;
  border: none;
  border-bottom: 5px solid #ced4da;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  transform: scale(1);
  animation: bottombord 0.3;
}

.form-group input:focus,
.form-group textarea:focus {
  transform: scale(1.02);
}

.form-group textarea {
  margin-top: 20px;
  min-height: 200px;
  border: none;
  border-bottom: 5px solid #ced4da;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  padding: 20px 30px 5px 30px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.btn-shadow {
  font-family: "Montserrat";
  background-color: #b79a20;
  color: #fff;
  padding: 10px 40px;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}

.btn-shadow a {
  color: #fff;
}

.btn-shadow a:hover {
  text-decoration: none;
}

.btn-shadow:hover {
  background-color: #000;
}

@media only screen and (max-width: 768px) {
  .logo-in-conact {
    width: 70%;
  }
}

@media only screen and (max-width: 414px) {
  .logo-in-conact {
    width: 100%;
  }

  .social-input {
    padding: 30px 0px;
    margin: 0 0 50px 10px !important;
  }
}

/* Media page styles */

.blessings-images {
  width: 100%;
  padding: 0 5px 10px 5px;
}

.joyful-title {
  font-family: "Mr Dafoe", cursive;
  font-size: 50px;
}

.gallery-intro h4 {
  font-size: 38px;
  font-weight: 600;
  padding-right: 20px;
}

.single-right-column {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
}

.single-left-column {
  padding-bottom: 20px;
  width: 100%;
}

.singles-title {
  position: absolute;
  top: 70px;
  right: 20px;
}

.gallery-subtitles h2 {
  font-family: "Mr Dafoe", cursive;
  font-size: 80px;
  color: #b79a20;
  line-height: 20px;
  margin-bottom: 35px;
}

.single-right-column #playListContainer {
  padding-right: 20px;
}

.activeAudio-single {
  display: block !important;
  width: 33.33%;
  float: left;
  height: 70px;
  margin-bottom: 10px;
}

.activeAudio-single .songs-play-full {
  border: 1px solid #fff;
}

.activeAudio-single .songs-play-full {
  margin-top: 10px;
}

#singles input[type="range"] {
  background: white !important;
}

@media only screen and (max-width: 1225px) {
  .activeAudio-single .songs-play-full {
    min-width: 175px !important;
    margin-left: 5px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .single-right-column {
    padding: 10px !important;
  }

  .activeAudio-single .stores-buttons {
    float: left !important;
    margin-left: 5px;
  }

  .activeAudio-single {
    padding: 0 5px;
  }

  .activeAudio-single .songs-play-full {
    min-width: 135px !important;
    margin-left: 5px !important;
  }

  .store-one {
    margin-right: 5px !important;
  }

  .activeAudio-single .songs-play-full p {
    font-size: 12px;
  }

  .singles-title {
    position: absolute;
    top: 40px;
    right: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .activeAudio-single {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }

  .activeAudio-single .stores-buttons {
    float: right !important;
    margin-left: 5px;
  }

  .singles-title {
    position: absolute;
    top: 40px;
    right: 10%;
  }
}

@media only screen and (max-width: 414px) {
  .second-gallery-2 {
    width: 50% !important;
    padding: 30px 0px 10px 0px;
  }

  .activeAudio-single {
    width: 90%;
    padding-left: 0px;
  }

  .singles-title {
    position: absolute;
    top: 90px;
    left: 50%;
    transform: translate(-30%);
  }
}

/* Lessons opages styles */

.the-family {
  display: block;
  padding-right: 45px !important;
  width: 100%;
}

.studio-photo {
  width: 100%;
}

.box-highlight {
  margin: 20px;
  padding: 25px;
  background-color: #b79a20;
  border-radius: 0.25rem;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease;
}

.box-highlight img {
  width: 40%;
  float: left;
  margin-right: 20px;
}

.box-highlight:hover {
  -webkit-transform: scale(1.1) skewY(2deg);
  -ms-transform: scale(1.1) skewY(2deg);
  transform: scale(1.1) skewY(2deg);
}

.richie {
  color: #fff;
}

.caption-lesson {
  color: #fff;
  position: absolute;
  bottom: 20px;
  left: 30px;
}

.lessons-for-all {
  text-align: center;
}

.lessons-for-all img {
  width: 100%;
  padding: 0 10px 0 10px !important;
}

.lessons-for-all:first-child {
  padding-right: 10px;
}

.lessons-for-all:last-child {
  padding-left: 10px;
}

.lessons-for-all h3 {
  margin-top: 20px;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: bold;
}

.worship-section {
  display: flex;
  align-content: center;
  align-items: center;
  margin: 50px 0 0 0;
}

.lessons-worship {
  width: 100%;
  position: absolute;
  height: 100%;
  object-fit: cover;
}

.worship-section h4 {
  font-family: "Mr Dafoe", cursive;
  color: #fff;
  font-size: 65px;
  line-height: 45px;
  z-index: 99;
  padding: 15% 15%;
}
.worship-section h4 span {
  font-size: 34px;
  font-weight: bold;
}

.worship-section p {
  z-index: 99;
  color: #fff;
  position: absolute;
  bottom: 15%;
  left: 15%;
}

.contact-me {
  width: 100%;
  text-align: center;
  margin: 50px 0;
}

.contact-me-b {
  padding: 30px 40px !important;
}

.contact-me-btn {
  color: #fff;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.75);
}

.contact-me-btn:hover {
  color: #fff !important;
  text-decoration: none;
}

@media only screen and (max-width: 414px) {
  .block-on-map {
    width: 150px;
    height: 150px;
  }

  .the-family {
    padding-right: 0 !important;
  }
  .worship-section h4 {
    font-size: 45px;
    line-height: 35px;
  }
  .worship-section h4 span {
    font-size: 24px;
  }

  .worship-section p {
    bottom: 5%;
    left: 15%;
    font-size: 11px;
  }

  .box-highlight img {
    width: 100%;
    float: left;
    margin-bottom: 10px;
  }
}

/* footer modifcations */

.footer-block {
  width: 100%;
  text-align: center;
  background-color: hsl(0, 0%, 13%);
  padding: 70px 0 50px 0;
}

.footer-block img {
  width: 94px;
  margin-left: auto;
  margin-right: auto;
}

.footer-closing {
  color: #fff;
  font-size: 34px;
}

.footer-closing span {
  color: #fff;
  font-size: 18px;
  font-weight: 300;
}

.footer-block p {
  color: #b79a20;
}

.footer-block p a {
  color: #fff;
}

.social-icons {
  color: #b79a20;
  margin: 0 10px;
}

.footer-navbar {
  width: 100%;
}

.mr-auto-footer {
  width: 100%;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.menu-items-footer {
  display: inline-block;
  text-align: center;
  color: #ffffff !important;
}

.menu-items-footer a {
  color: #b79a20;
  margin: 0 10px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

.menu-items-footer .active {
  color: #000 !important;
}

.menu-items-footer a:hover {
  text-decoration: none;
  color: #474747;
}

.containerPlayer {
  padding: 20px 30px;
  align-content: center;
  width: 100%;
}

#listContainer {
  width: 100%;
  float: left;
}

#listContainer ul {
  background-color: transparent;
  clear: both;
  cursor: pointer;
  padding-left: 0 !important;
}

#listContainer li:hover,
#listContainer li:nth-child(even):hover {
  background-color: transparent;
  color: #fff;
}

#listContainer li a {
  text-decoration: none;
  color: #fff;
}

#listContainer li {
  height: 90px;
}

#listContainer li.activeAudio {
  background-color: transparent;
}

.activeAudio img {
  transition: all 0.2s ease-in-out;
}

.activeAudio img:hover {
  transform: scale(1.1);
}

#playListContainer li {
  display: none;
}

#playListContainer li.activeAudio {
  display: block;
}

#listContainer li a img {
  float: left;
}

#listContainer li a h5 {
  margin-left: 122px;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  transform: translateY(33%);
}

.songs-play {
  display: block;
  margin-left: 32px;
  float: left;
  font-size: 10px;
  border: 1px solid #b79a20;
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: 25px;
}

.songs-play:last-child {
  margin-left: 10px;
}

.songs-play:hover {
  background-color: #fff;
  color: #000 !important;
}

#listContainer li a h5 span {
  font-size: 10px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.5);
  display: block;
}

input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #b79a20 !important;
  height: 2px;
  width: 100%;
  transform: rotate(0deg);
  position: absolute;
  left: 34px;
  top: 23px;
  border-radius: 5px;
  border: none !important;
  outline: 0;
  z-index: 9999;
  box-shadow: none !important;
  opacity: 1 !important;
  visibility: visible !important;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid #b79a20;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  border: 1px solid #b79a20;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
}

input[type="range"]::-ms-thumb {
  height: 12px;
  width: 12px;
  border: 1px solid #b79a20;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
}

#playerContainer {
  height: auto;
  float: left;
  margin-top: 6px;
  width: 100%;
}

.clearfix {
  overflow: auto;
}

.controls {
  padding-left: 0 !important;
  display: block;
  width: 100%;
}

.controls-firstline {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.album-left-column .controls-firstline {
  height: 65px;
  margin-bottom: 20px;
}

.player-icon {
  display: block;
  float: left;
  width: 55px;
}

.player-progress {
  display: flex;
  float: left;
  width: 80%;
  padding-bottom: 7px;
}

.player-time {
  display: block;
  float: right;
  text-align: center;
  width: 55px;
}

.playAudio,
.play {
  background: url("./resources/images/audio/play.png") no-repeat;
  width: 34px;
  height: 34px;
  margin: 0 7.5px;
  cursor: pointer;
  background-size: contain;
  filter: sepia(100%);
}

.pauseAudio,
.pause {
  background: url("./resources/images/audio/pause.png") no-repeat;
  width: 34px;
  height: 34px;
  margin: 0 7.5px;
  cursor: pointer;
  background-size: contain;
}

.shuffle {
  background: url("./resources/images/audio/shuffle.png") no-repeat;
  width: 16px;
  height: 64px;
}

.shuffleActive,
.shuffle:hover {
  background: url("./resources/images/audio/shuffle.png") no-repeat;
  width: 16px;
  height: 64px;
}

.left {
  background: url("./resources/images/audio/prev.png") no-repeat;
  height: 64px;
  width: 16px;
  margin: auto;
  margin-top: 0px;
  opacity: 1;
  cursor: pointer;
}

.left:not(.disabled):hover {
  opacity: 0.4;
}

.left.disabled,
.right.disabled {
  opacity: 0.4;
  cursor: default;
}

.right {
  background: url("./resources/images/audio/next.png") no-repeat;
  height: 64px;
  width: 16px;
  margin: auto;
  margin-top: 0px;
  opacity: 1;
  cursor: pointer;
}

.right:not(.disabled):hover {
  opacity: 0.4;
}

.repeat {
  background: url("./resources/images/audio/replay.png") no-repeat;
  width: 16px;
  height: 64px;
}

.repeat:hover,
.repeat.loopActive {
  background: url("./resources/images/audio/replay.png") no-repeat;
  width: 16px;
  height: 64px;
}

.mute {
  background: url("./resources/images/audio/mute.png") no-repeat;
  margin-top: -8px;
}

.progress {
  display: block;
  position: relative;
  background-color: transparent !important;
  width: 100%;
  cursor: pointer;
  box-shadow: none !important;
}

.progress .updateProgress {
  width: 0%;
  background-color: #b79a20;
  height: 100%;
  float: left;
  position: relative;
}

input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #00bd9b;
  height: 2px;
}

.audioDetails {
  clear: both;
  color: #00bd9b;
  font-size: 12px;
  padding: 5px;
}

.audioTime {
  display: inline-block;
  text-align: center;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding-bottom: 7px;
}

.songPlay {
  display: inline-block;
}

.seekableTrack {
  width: 0%;
  background-color: #ffffff !important;
  height: 2px;
  position: absolute;
  z-index: 0;
  display: block;
  left: 0px !important;
  top: 61% !important;
}

.seekableTrack,
.progress .updateProgress {
  -webkit-transition: width 0.6s ease;
  -moz-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

.repeat,
.shuffle {
  margin: 0 10.5px;
  cursor: pointer;
}

.bar {
  transition: 0.4s;
}

@media only screen and (max-width: 414px) {
  #playerContainer {
    margin-top: 7px !important;
  }

  .containerPlayer {
    padding: 25px 30px 0 30px;
  }

  .controls-firstline {
    width: 90%;
  }
}

.containerPlayer {
  padding: 20px 30px;
  align-content: center;
  width: 100%;
}

/* Media page design */

.album-left-column {
  background-color: #000;
  text-align: center;
}

.album-right-column {
  background-color: hsl(0, 0%, 13%);
  padding: 10px 50px !important;
}

.cover-left {
  margin: 20px 0 0 0;
  width: 90%;
}

#listContainer {
  width: 100%;
  float: left;
}

#listContainer ul {
  background-color: transparent;
  clear: both;
  cursor: pointer;
  padding-left: 0 !important;
}

#listContainer li:hover,
#listContainer li:nth-child(even):hover {
  background-color: transparent;
  color: #fff;
}

#listContainer li a {
  text-decoration: none;
  color: #fff;
}

#listContainer li {
  height: 70px;
}

#listContainer li.activeAudio {
  background-color: transparent;
}

.activeAudio img {
  transition: all 0.2s ease-in-out;
}

.activeAudio img:hover {
  transform: scale(1.1);
}

#playListContainer li {
  display: none;
}

#playListContainer li.activeAudio {
  display: block;
  border-bottom: 1px dotted #7e7e7e;
  margin-bottom: 10px;
}

#playListContainer li.activeAudio:last-child {
  border-bottom: none;
}

#listContainer li a img {
  float: left;
}

#listContainer li a h5 {
  margin-left: 122px;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  transform: translateY(33%);
}

.songs-play-full {
  display: block;
  margin-left: 32px;
  float: left;
  font-size: 10px;
  border: 1px solid #b79a20;
  border-radius: 5px;
  padding: 10px 10px;
  margin-top: 5px;
  min-width: 250px;
}

.songs-play:last-child {
  margin-left: 10px;
}

.songs-play-full:hover {
  background-color: #fff;
  color: #000 !important;
}

.songs-play-full p {
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  display: block;
  margin-bottom: 0;
}

.stores-buttons {
  display: flex;
  float: right;
  height: 70px;
  align-items: center;
  align-content: center;
}

.store-one {
  margin-right: 20px;
}

#listContainer li a h5 span {
  font-size: 10px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.5);
  display: block;
}

input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #b79a20 !important;
  height: 2px;
  width: 100%;
  transform: rotate(0deg);
  position: absolute;
  left: 34px;
  top: 23px;
  border-radius: 5px;
  border: none !important;
  outline: 0;
  z-index: 9999;
  box-shadow: none !important;
  opacity: 1 !important;
  visibility: visible !important;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid #b79a20;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  border: 1px solid #b79a20;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
}

input[type="range"]::-ms-thumb {
  height: 12px;
  width: 12px;
  border: 1px solid #b79a20;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
}

#playerContainer {
  display: inline-block;
  height: auto;
  margin-top: 40px;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  display: block;
}

.clearfix {
  overflow: auto;
}

.controls {
  padding-left: 0 !important;
  display: block;
  width: 100%;
}

.controls-firstline {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.player-icon {
  display: block;
  float: left;
  width: 55px;
}

.player-progress {
  display: flex;
  float: left;
  width: 80%;
  padding-bottom: 7px;
}

.player-time {
  display: block;
  float: right;
  text-align: center;
  width: 55px;
}

.playAudio,
.play {
  background: url("./resources/images/audio/play.png") no-repeat;
  width: 34px;
  height: 34px;
  margin: 0 7.5px;
  cursor: pointer;
  background-size: contain;
  filter: sepia(100%);
}

.pauseAudio,
.pause {
  background: url("./resources/images/audio/pause.png") no-repeat;
  width: 34px;
  height: 34px;
  margin: 0 7.5px;
  cursor: pointer;
  background-size: contain;
}

.shuffle {
  background: url("./resources/images/audio/shuffle.png") no-repeat;
  width: 16px;
  height: 64px;
}

.shuffleActive,
.shuffle:hover {
  background: url("./resources/images/audio/shuffle.png") no-repeat;
  width: 16px;
  height: 64px;
}

.left {
  background: url("./resources/images/audio/prev.png") no-repeat;
  height: 64px;
  width: 16px;
  margin: auto;
  margin-top: 0px;
  opacity: 1;
  cursor: pointer;
}

.left:not(.disabled):hover {
  opacity: 0.4;
}

.left.disabled,
.right.disabled {
  opacity: 0.4;
  cursor: default;
}

.right {
  background: url("./resources/images/audio/next.png") no-repeat;
  height: 64px;
  width: 16px;
  margin: auto;
  margin-top: 0px;
  opacity: 1;
  cursor: pointer;
}

.right:not(.disabled):hover {
  opacity: 0.4;
}

.repeat {
  background: url("./resources/images/audio/replay.png") no-repeat;
  width: 16px;
  height: 64px;
}

.repeat:hover,
.repeat.loopActive {
  background: url("./resources/images/audio/replay.png") no-repeat;
  width: 16px;
  height: 64px;
}

.mute {
  background: url("./resources/images/audio/mute.png") no-repeat;
  margin-top: -8px;
}

.progress {
  display: block;
  position: relative;
  background-color: transparent !important;
  width: 100%;
  cursor: pointer;
  box-shadow: none !important;
}

.progress .updateProgress {
  width: 0%;
  background-color: #b79a20;
  height: 100%;
  float: left;
  position: relative;
}

.now-playing {
  font-size: 12px;
  color: white;
}

.now-playing span {
  font-size: 16px;
  font-weight: 600;
  color: white;
}

input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #00bd9b;
  height: 2px;
}

.audioDetails {
  clear: both;
  color: #00bd9b;
  font-size: 12px;
  padding: 5px;
}

.audioTime {
  display: inline-block;
  text-align: center;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding-bottom: 7px;
}

.songPlay {
  display: inline-block;
}

.seekableTrack {
  width: 0%;
  background-color: #ffffff !important;
  height: 2px;
  position: absolute;
  z-index: 0;
  display: block;
  left: 0px !important;
  top: 61% !important;
}

.seekableTrack,
.progress .updateProgress {
  -webkit-transition: width 0.6s ease;
  -moz-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

.repeat,
.shuffle {
  margin: 0 10.5px;
  cursor: pointer;
}

.bar {
  transition: 0.4s;
}

@media only screen and (max-width: 414px) {
  #playerContainer {
    margin-bottom: 40px;
  }

  #listContainer li {
    height: 70px;
  }

  .album-right-column {
    padding: 20px 30px !important;
  }

  .songs-play-full {
    margin-left: 0;
  }

  .songs-play-full {
    min-width: 180px;
  }

  .songs-play-full p {
    font-size: 12px;
    font-weight: 500;
  }
}

@media only screen and (max-width: 320px) {
  #listContainer li {
    height: 75px;
  }

  .songs-play-full {
    min-width: 100px;
    max-width: 155px;
  }

  .songs-play-full p {
    font-size: 10px;
  }

  .album-right-column {
    padding: 20px 10px !important;
  }
}

/* All about Dashboard */
.profile-avatar-top {
  width: 70px;
  height: 70px;
  border-radius: 100px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  border: 4px solid #b79a20;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.9);
}

.profile-avatar-top img {
  width: 100%;
}

.text-right {
  margin-top: 50px !important;
}

.dashboard-menu {
  color: #b79a20;
  display: block;
  font-size: 14px;
  border: 1px solid #b79a20;
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: 25px;
}

.dashboard-menu-block {
  padding: 10px 30px;
  background-color: #f9f9f9;
}

.dashboard-goback {
  color: #b79a20;
  display: block;
  font-size: 14px;
  border: 1px solid #b79a20;
  border-radius: 5px;
  padding: 5px 10px;
  float: left;
  margin-right: 10px;
}

.email-item:first-child {
  margin-top: 50px;
}

.email-item:last-child {
  margin-bottom: 50px;
}

.email-item {
  background-color: #f8f8f8;
  padding: 20px 30px;
}

.email-top {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.email-messages {
  display: block;
}

.email-item h5 {
  float: left;
}

.email-phone {
  float: right;
}

.labels {
  margin-bottom: 0;
  font-size: 10px;
}

.email-item ul {
  padding-left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.email-item li {
  font-size: 12px;
}

.email-item:nth-child(even) {
  background-color: #ffffff;
  padding: 20px 30px;
}

.form-group input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  float: left;
  margin: 3px 20px 0 25px !important;
  padding: 0 !important;
  width: auto;
  min-height: 50px;
  border: none;
  border-bottom: 5px solid #ced4da;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  transform: scale(1);
  animation: bottombord 0.3;
}

.right-side-dash {
  padding: 0 40px;
}

table {
  width: 100%;
}

th {
  border-bottom: 1px solid #b79a20;
  background-color: #fff;
}

td,
th {
  height: 50px;
  padding: 5px 20px;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

.dashboard-delete-red {
  border: none;
  background-color: darkred;
  border-radius: 5px;
  color: #fff;
  line-height: 0;
  padding: 5px 10px;
}

.profiles-items {
  border-top: 1 solid #b79a20;
}

.profiles-items:first-child {
  border-top: none;
}

.profile-avatar {
  border-radius: 10px;
  overflow: hidden;
  width: 80%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.profile-avatar img {
  width: 100%;
}

ul {
  list-style: none;
}

li {
  color: #b79a20;
  padding: 10px 0;
}

.profiles-in {
  margin: 20px 15% !important;
}

.bio-in-profile {
  margin: 20px 0;
}

.skills-in-profile {
  padding: 10px 20px;
  background-color: #b79a20;
  color: #fff;
  display: block;
  float: left;
  border-radius: 5px;
  margin-right: 10px;
}

.social-in-profile {
  border-top: 1px solid #ececec;
  margin-top: 25px;
  padding-top: 20px;
  width: 100%;
  display: flex;
}

.social-in-profile-a a {
  color: #b79a20;
}

.skills-block-in-profile {
  display: flex;
}

.profiles-items p {
  padding: 30px 40px 0 0;
}

.exp-in-profile {
  display: block;
  width: 100%;
  margin-top: 30px;
  padding: 20px 30px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 900px) {
  .email-top h5 {
    font-size: 18px;
  }

  .email-messages {
    flex-direction: column !important;
  }

  .email-messages li {
    padding: 2px 0;
  }
}

@media only screen and (max-width: 414px) {
  .right-side-dash {
    padding: 30px 10px !important;
  }

  .profile-avatar-top {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .profiles-items p {
    padding: 30px 0 0 0;
  }

  .profile-avatar {
    width: 40%;
    margin-right: auto;
    margin-left: auto;
  }

  .profiles-items h3,
  .profiles-items h5,
  .profiles-items p {
    text-align: center;
    margin-top: 20px;
  }
  .hide-sm {
    display: none;
  }
  .profiles-items ul,
  .profiles-items .dashboard-menu {
    width: 70%;
    margin-right: auto;
    margin-left: auto;
  }
  .skills-block-in-profile {
    display: flex;
    flex-direction: column;
  }

  .skills-in-profile {
    font-size: 12px;
    margin: 10px 0 !important;
  }

  .footer-closing {
    font-size: 28px;
  }
}

.user-in-posts {
  margin-bottom: 30px;
}

.posts-avatar {
  width: 70px;
  height: 70px;
  border-radius: 5px;
}

.posts-data h5 {
  font-size: 16px;
  font-weight: 700;
  line-height: 1em;
}

.posts-data p {
  font-size: 12px;
  margin-bottom: 0;
}

.counts {
  color: #fff;
  background-color: #000;
  font-size: 10px;
  padding: 2px 7px 2px 7px;
  border-radius: 3px;
  margin-left: 5px;
  vertical-align: middle;
}

.post-like {
  background-color: transparent;
  border: none;
  margin-left: 5px;
  line-height: 2em;
  vertical-align: middle;
}

.read {
  margin-left: 10px;
  line-height: 2em;
}

.card-title {
  font-size: 34px !important;
  line-height: 1em;
}

.card-footer a {
  color: rgba(0, 0, 0, 0.85);
  margin-left: 15px;
  float: right;
}

.card-footer .btn {
  margin-left: 15px;
}

.all-happenings {
  margin: 50px 0;
}

.all-happenings .col-lg-4,
.all-happenings .col-md-6,
.all-happenings .col-sm-12 {
  margin: 0 0 30px 0 !important;
}

.card {
  margin: 0 10px;
  border-radius: 10px;
  align-self: baseline;
  height: 100%;
}

.card-title a {
  color: #000;
  line-height: 1em;
}

.card-img-top {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.ql-container {
  min-height: 500px;
}

.post-container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  height: 100%;
}

.full-post-image {
  width: 100%;
  display: block;
}

.full-post-image img {
  width: 100%;
  max-height: 500px;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
}

.full-post-intro {
  margin-top: 50px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.full-post-intro .user-in-posts {
  width: 30%;
  float: left;
}

.full-post-intro .card-title {
  width: 70%;
  float: left;
  padding-left: 30px;
}

.full-post-intro .card-text {
  width: 70%;
  float: left;
  padding-left: 30px;
  display: block;
}

.full-post-intro .dashboard-delete-red {
  border-radius: 5px;
  padding: 20px 30px;
}

.full-post-intro .dashboard-delete-red:hover {
  text-decoration: none;
  color: #ced4da;
}

.full-post-body {
  margin: 50px 0;
  display: block;
}

.full-post-interaction {
  width: 100%;
  margin-top: 20px;
}

.full-post-interaction .post-like {
  padding-left: 0;
  margin-left: 0;
}

.full-post-intro .dashboard-goback {
  float: left;
  font-size: 12px;
  margin-top: 20px;
}

.comments-container {
  margin-top: 50px;
  margin-left: 35%;
  width: 55%;
}

.comments-container .ql-container {
  min-height: 200px;
}

.posts-block-comment {
  background-color: rgba(0, 0, 0, 0.03);
  width: 100%;
  margin: 20px 0;
  padding: 30px 35px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.posts-block-comment .full-post-body {
  margin: 20px 0 20px 16.666667%;
}

.posts-avatar-comment {
  width: 45px;
  height: 45px;
  margin-bottom: 30px;
}

.posts-data-comment p {
  font-size: 11px;
  line-height: 0;
}

.posts-data-comment h5 {
  font-size: 18px;
}

.alts {
  position: fixed;
  top: 0px;
  right: 0;
  height: auto;
  width: 150px;
  z-index: 99999;
}

@media only screen and (max-width: 414px) {
  .right-side-dash {
    padding: 40px;
  }
  .full-post-intro .user-in-posts {
    width: 100%;
  }

  .full-post-intro .card-title {
    width: 100%;
    padding-left: 0;
  }

  .full-post-intro .card-text {
    width: 100%;
    float: left;
    padding-left: 0;
  }

  .comments-container {
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }
}

.upload-input {
  width: 300px !important;
  float: left;
  border: none !important;
  box-shadow: none !important;
}

.Upload {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  text-align: left;
  overflow: hidden;
}

.Content {
  display: flex;
  flex-direction: row;
  padding-top: 16px;
  box-sizing: border-box;
  width: 100%;
}

.Files {
  margin-left: 32px;
  align-items: flex-start;
  justify-items: flex-start;
  flex: 1;
  overflow-y: auto;
}

.Actions {
  display: flex;
  flex: 1;
  width: 100%;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 32px;
}

.Title {
  margin-bottom: 32px;
  color: #555;
}

.Filename {
  margin-bottom: 8px;
  font-size: 16px;
  color: #555;
}

.Row {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  height: 50px;
  padding: 8px;
  overflow: hidden;
  box-sizing: border-box;
}

.Dropzone {
  height: 200px;
  width: 200px;
  background-color: #fff;
  border: 2px dashed rgb(187, 186, 186);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
}

.Highlight {
  background-color: rgb(188, 185, 236);
}

.Icon {
  opacity: 0.3;
  height: 64px;
  width: 64px;
}

.FileInput {
  display: none;
}

.ProgressBar {
  width: 100%;
  height: 8px;
  background-color: rgb(183, 155, 229);
  border-radius: 5px;
}

.Progress {
  background-color: rgba(103, 58, 183, 1);
  height: 100%;
  margin: 0;
  border-radius: 5px;
}

.fi-container {
  margin: 0 0 0 30px;
  padding: 50px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.custom-file-label {
  position: relative;
  width: 100%;
}

.uploaded-image img {
  width: 100%;
}

.custom-file-input-form {
  font-size: 11px;
  width: 100%;
  background-color: transparent;
  border: none;
}

.post-card-img {
  max-height: 200px;
  object-fit: cover;
  overflow: hidden;
}

.event-info {
  display: flex;
}

.react-datepicker-wrapper {
  width: 100%;
}
